<template>
    <v-container fluid>
        <v-alert dense outlined type="error" v-if="Object.keys(validation_errors).length > 0">
            <ul>
                <li v-for="(errors, field) in validation_errors" :key="field">
                    {{ field }}
                    <ul>
                        <li v-for="(error, sl) in errors" :key="sl">
                            {{ error }}
                        </li>
                    </ul>
                </li>
            </ul>
        </v-alert>
        <v-form class="custom-form" ref="salaryForm" @submit.prevent="generateSalary">
            <v-row dense>
                <v-col cols="12" md="4" offset-md="2">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Salary Information</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <v-row>
                                <v-col cols="5" class="py-1 caption">Entry Date</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-menu max-width="290" v-model="menu" :close-on-content-click="false">
                                        <template v-slot:activator="{ on }">
                                            <v-text-field dense readonly outlined hide-details v-on="on"
                                                v-model="salary.entry_date" :rules="[() => !!salary.entry_date || '']"
                                                :error-messages="validation_errors.entry_date"></v-text-field>
                                        </template>
                                        <v-date-picker no-title v-model="salary.entry_date"
                                            @input="menu = false"></v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Month</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-combobox placeholder="Salary Month" dense outlined hide-details :items="months"
                                        item-text="name" item-value="id" class="custom-form" v-model="selectedMonth"
                                        @focus="getMonths" :rules="[() => !!salary.month_id || '']"
                                        :error-messages="validation_errors.month_id" append-outer-icon="mdi-plus"
                                        @click:append-outer="$router.push('/month')"></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Date From</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field v-model="salary.date_from" dense outlined hide-details
                                        autocomplete="off" readonly></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="5" class="py-1 pr-1 caption">Date To</v-col>
                                <v-col cols="7" class="py-0">
                                    <v-text-field v-model="salary.date_to" dense outlined hide-details
                                        autocomplete="off" readonly></v-text-field>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                </v-col>
                <v-col cols="12" md="4">
                    <v-card height="100%" :elevation="1">
                        <v-toolbar color="white" :elevation="1" height="30px">
                            <v-toolbar-title class="subtitle-2">Other Information</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-row class="pt-2">
                                <v-col cols="4" class="py-1 caption">Approved By</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-combobox label="Approved By" dense outlined hide-details :items="employees"
                                        item-text="display_name" item-value="id" class="custom-form"
                                        v-model="selectedApprovedBy" @focus="getEmployees"
                                        :rules="[() => !!salary.approved_by || '']"
                                        :error-messages="validation_errors.approved_by"></v-combobox>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="4" class="py-1 caption">Remark</v-col>
                                <v-col cols="8" class="py-0">
                                    <v-textarea dense outlined hide-details v-model="salary.remark">
                                    </v-textarea>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12" class="text-right pb-0">
                                    <v-btn height="30px" dark color="light-blue darken-2 mr-1" :loading="loading"
                                        :elevation="1" type="submit">Generate</v-btn>
                                    <v-btn height="30px" dark color="deep-orange" :elevation="1"
                                        @click="resetForm">Cancel</v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-form>
        <v-row v-if="salaries.length > 0">
            <v-col cols="12" class="py-0">
                <v-toolbar dense color="white" :elevation="1" height="30px">
                    <v-toolbar-title class="subtitle-2">Last Year Record</v-toolbar-title>
                </v-toolbar>
            </v-col>
            <v-col cols="12" md="12" class="py-1" id="reportContent">
                <v-simple-table dense class="record_table">
                    <thead>
                        <tr class="record_heading_tr">
                            <th>SL.</th>
                            <th>Entry Date</th>
                            <th>Month</th>
                            <th>Date From</th>
                            <th>Date To</th>
                            <th>Total Gross Salary</th>
                            <th>Total PF</th>
                            <th>Total Deduction</th>
                            <th>Total Advance</th>
                            <th>Total Benefit</th>
                            <th>Total Loan Received</th>
                            <th>Total OT</th>
                            <th>Total Employee Due</th>
                            <th>Total Net Payable</th>
                            <th>Approved By</th>
                            <th>Remark</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody class="text-center">
                        <tr v-for="(record, sl) in salaries" :key="sl">
                            <td>{{+ + sl }}</td>
                            <td>{{ record.entry_date }}</td>
                            <td>{{ record.month_name }}</td>
                            <td>{{ record.date_from }}</td>
                            <td>{{ record.date_to }}</td>
                            <td>{{ record.total_gross_salary }}</td>
                            <td>{{ record.total_pf }}</td>
                            <td>{{ record.total_deduction }}</td>
                            <td>{{ record.total_advance }}</td>
                            <td>{{ record.total_benefit }}</td>
                            <td>{{ record.total_loan_received }}</td>
                            <td>{{ record.total_ot_amount }}</td>
                            <td>{{ record.total_overtake_salary }}</td>
                            <td>{{ record.total_net_payable }}</td>
                            <td>{{ record.approved_by_name }}</td>
                            <td>{{ record.remark }}</td>
                            <td>
                                <v-btn icon x-small color="error" :to="'/salary_sheet/' + record.id">
                                    <v-icon>mdi-printer</v-icon>
                                </v-btn>
                                <v-btn icon x-small color="error" :to="'/bank_sheet/' + record.id">
                                    <v-icon>mdi-bank</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import utility from '../../mixins/utility.mixin';
export default {
    mixins: [utility],
    data() {
        return {
            employees: [],
            selectedApprovedBy: null,
            months: [],
            selectedMonth: null,
            salary: {
                month_id: null,
                entry_date: this.toISOLocal().substr(0, 10),
                remark: '',
                approved_by: null,
                date_from: null,
                date_to: null,
            },
            salaries: [],
            loading: false,
            menu: false,
            validation_errors: {},
        }
    },
    watch: {
        selectedApprovedBy(emp) {
            this.salary.approved_by = null;
            if (!emp || emp.id == undefined) {
                return;
            }
            this.salary.approved_by = emp.id;
        },
        selectedMonth(month) {
            this.salary.month_id = null;
            this.salary.date_from = null;
            this.salary.date_to = null;
            if (!month || month.id == undefined) {
                return;
            }
            this.salary.month_id = month.id;
            this.salary.date_from = month.date_from;
            this.salary.date_to = month.date_to;
        }
    },
    async created() {
        this.getSalaries();
    },
    methods: {
        async getSalaries() {

            this.salaries = await this.$store.dispatch('salary/getSalaries', {
                apiParams: {
                    last_year: true,
                },
                options: {
                    returnData: true,
                }
            });
        },
        async getEmployees() {
            this.employees = await this.$store.dispatch('employee/getEmployees', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
        },
        async getMonths() {
            this.months = await this.$store.dispatch('month/getSalaryMonths', {
                apiParams: {},
                options: {
                    returnData: true
                }
            });
        },
        async generateSalary() {

            if (!this.$refs.salaryForm.validate()) {
                this.$store.dispatch('snackbar/error', 'Fill red marked fields');
                return;
            }

            this.loading = true;

            let resObj = await this.$store.dispatch('salary/generateSalary', this.salary);

            if (resObj.hasOwnProperty("validation_errors")) {
                this.validation_errors = resObj.validation_errors;
            }
            this.loading = false;
            if (resObj.success) {
                this.resetForm();
                this.getSalaries();
            }
        },
        resetForm() {
            this.selectedMonth = null;
            this.selectedApprovedBy = null;

            this.validation_errors = {};

            this.salary = {
                month_id: null,
                entry_date: this.toISOLocal().substr(0, 10),
                remark: '',
                approved_by: null,
                date_from: null,
                date_to: null,
            };

            this.$refs.salaryForm.resetValidation();
        },
    },
}
</script>

<style scoped>
.v-sheet.v-card:not(#employee_info) {
    background: #6ef6f63d !important;
}

.theme--light.v-toolbar.v-sheet {
    background-color: #5df0f096 !important;
}

.v-card__text .caption {
    color: #000;
}

>>>.v-text-field--outlined .v-input__append-outer {
    margin-top: 0 !important;
}

>>>.v-text-field--outlined .v-input__append-outer button {
    color: #fff;
    background: #3e2e6b;
}

.record_table {
    margin-bottom: 5px;
}

.record_table table,
.record_table th,
.record_table td {
    border: 1px solid #dee2e6;
    border-collapse: collapse;
    padding: 0 !important;

}

.record_table th,
.record_table td {
    padding: 2px !important;
    font-size: 11px !important;
    text-align: center !important;
    height: 0 !important;
}

.record_heading_tr {
    background: #4caf7773;
}
</style>